





























































import Payment from '../../includes/logic/Payment'
import logosMixin from '@/mixins/logosMixin'
import configMixin from '../../mixins/chatConfig'
import { ChatInfo } from '@/includes/types'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '../../mixins/input-setups'
import { errorNotification } from '@/includes/NotificationService'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { ValidationObserver } from "vee-validate"

@Component({
  'mixins': [ configMixin, logosMixin ],

  'components': {
    CenteredColumnLayout,
    ValidationObserver,
    EmptyData
  }
})
export default class ChooseChat extends Mixins(UseFields, InputSetups) {
  form: { chatId: string | null } = {
    chatId: null
  }

  hasChats: boolean = true

  goToChatBuyPage(): Promise<Route> {
    const { ...query } = this.$route.query
    delete query.returnUrl; // if exists
    return this.$router.push({
      path : `/chat/${ this.form.chatId }/buy/`,
      query: query,
    });
  }

  goToAddChatPage(): Promise<Route> {
    return this.$router.push({ 'name': 'start_add_chat' })
  }

  async getChatsOptions(): Promise<Array<SelectOptionData>> {
    try {
      await this.$store.dispatch("requestChatsInfo", this);
    } catch (error) {
      errorNotification(error)
    }

    const chatsInfo: Array<ChatInfo> = this.$store.state.chatState.chatsInfo.chats

    this.hasChats = chatsInfo.length !== 0

    return [ ...chatsInfo ].map((item: ChatInfo): SelectOptionData => ({
      label: item.group_title,
      value: item.chat_id,
      image: {
        src: item.group_photo,
      }
    }))
  }

  beforeCreate(): void {
    /*
     Example of a right URL: /buy-license/choose-chat/?tariff=starter&monthCount=12
     ?redirectPath - could be exist
     */
    if(Object.keys(this.$route.query).length === 0 || !Payment.isVerifiedChatLicenseBuyQueryParams(this.$route.query)) {
      (this as any).$replaceRoute({ name: 'error_404' })
    }
  }
}
